import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const WhyUnderlineHeader = (props) => {
  const { howItWorks, seeInAction } = props.data
  return (
    <header className="has-text-centered section-header">
      <h4 className="subtitle has-text-weight-semibold is-size-4">
        {howItWorks}
      </h4>
      <h2
        className="has-text-weight-bold is-size-2"
        style={{ color: '#1E222D' }}
      >
        {seeInAction}
      </h2>
    </header>
  )
}
const EN_YOUTUBE_ID = 'bcJcmLdmhDA'
const PL_YOUTUBE_ID = 'nA1kvWRwklk'
export const Video = (props) => {
  const { data: { whyUnderline } } = props
  const context = React.useContext(I18nextContext)

  let youtubeId = EN_YOUTUBE_ID
  if (context.language === 'pl') {
    youtubeId = PL_YOUTUBE_ID
  }

  return (
    <section className="hero" style={{ marginTop: 120 }} id="aboutUnderline">
      <div className="hero-body">
        <div className="container">
          <WhyUnderlineHeader {...props} />

          <div className="is-justify-content-center"
               style={{ marginTop: 80, filter: 'drop-shadow(17.5781px 17.5781px 70.3125px rgba(86, 98, 128, 0.1))' }}>
            <LiteYouTubeEmbed
              poster="maxresdefault"
              params="rel=0&modestbranding=1"
              aspectHeight={8}
              id={youtubeId}
              title="Underline"
            />
          </div>


        </div>
      </div>

    </section>
  )
}