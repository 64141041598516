import React from 'react'


export const LandingPageImage = ({ src, srcSet, style, withoutShadow, alt }) => {
  return (
    <>
      <div className="column is-justify-content-flex-end is-hidden-mobile is-flex">
        <div className={`${withoutShadow === true ? '' : 'image-container'}`}>
          <img
            src={src}
            srcSet={srcSet}
            style={style}
            alt={alt}
          />
        </div>
      </div>
      <div className="column is-justify-content-center is-hidden-tablet	is-flex" >
        <div className={`${withoutShadow === true ? '' : 'image-container'}`}>
          <img
            src={src}
            srcSet={srcSet}
            alt={alt}
          />
        </div>
      </div>
    </>
  )
}