import React, { useState } from 'react';
import { Footer } from '../../components/Footer';
import { Menu } from '../../components/Menu/Menu';
import SEO from '../../components/Seo/Seo';
import { LearnMoreContext, TranslationContext } from '../../services/context/translationContext';
import '../../styles/style.scss';
import { CustomerReviews } from './CustomerReviews';
import { DownloadButtons } from './DownloadButtons';
import { HowItWorks } from './HowItWorks';
import { LearningMode } from './LearningMode';
import { LearnWhatYouNeed } from './LearnWhatYouNeed';
import { PurpleSection } from './PurpleSection';
import { TopSection } from './TopSection';
import { Video } from './Video';
import { WhyUnderline } from './WhyUnderline';

export const Landing = (props) => {
  const { datoCmsLanding } = props.data
  const { howItWorksWhite } = datoCmsLanding
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  return (
    <>
      <SEO title="Rozszerzaj słownictwo z języka obcego" />
      <TranslationContext.Provider value={datoCmsLanding} >
        <LearnMoreContext.Provider value={{setIsVisibleModal, isVisibleModal}}>
          <Menu data={props.data} location={props.location} />
          <main>
            <TopSection data={datoCmsLanding} setIsVisibleModal={setIsVisibleModal} />
            <Video data={datoCmsLanding} />
            <WhyUnderline data={datoCmsLanding} setIsVisibleModal={setIsVisibleModal} />
            {/* purple */}
            <HowItWorks data={datoCmsLanding} />
            <div style={{ marginTop: 60 }}>
              {howItWorksWhite.map((item, index) => {
                if (index !== 0) {
                  if (index % 2 === 0) {
                    return <LearningMode index={index} data={datoCmsLanding} item={item} setIsVisibleModal={setIsVisibleModal} />
                  }
                  return <LearnWhatYouNeed data={datoCmsLanding} item={item} setIsVisibleModal={setIsVisibleModal} />
                }
              })}
            </div>

            <PurpleSection data={datoCmsLanding} setIsVisibleModal={setIsVisibleModal} />
            <CustomerReviews data={datoCmsLanding} />
            {/*<Pricing setIsVisibleModal={setIsVisibleModal} />*/}
            <div style={{marginTop: 160, marginBottom: 90}}>
              <DownloadButtons
                location="footer"
                id="footer_download_buttons"
                buttonCta={datoCmsLanding.buttonCta}
              />
            </div>
          </main>
          <Footer data={datoCmsLanding} />
          <div className={`modal ${isVisibleModal ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setIsVisibleModal(false)}></div>
            <div className="modal-content" style={{maxWidth: 1000, width: '100%'}}>
              <DownloadButtons buttonCta={datoCmsLanding.buttonCta} location="modal" />
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => setIsVisibleModal(false)}></button>
          </div>
        </LearnMoreContext.Provider>
      </TranslationContext.Provider>
    </>
  )
}