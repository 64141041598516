import { I18nextContext, Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react';
import flagPl from 'svg-country-flags/svg/pl.svg';
import flagUs from 'svg-country-flags/svg/us.svg';
import Images from '../../images';

export const Menu = (props) => {
  const [isActive, setisActive] = React.useState(false);

  const { originalPath, navigate } = useI18next();
  const { t } = useTranslation()
  const context = React.useContext(I18nextContext);

  const availableLanguages = props.data.allDatoCmsPageLanguage.edges
  const { menu: menuContent } = props.data.datoCmsLanding
  const currentFlag = availableLanguages.find(item => item.node.abbreviation === context.language).node.flag

  const onClick = (e, anchor) => {
    e.preventDefault()
    if (props.location.pathname == "/" && anchor.includes("#")) {
      scrollTo(anchor)
    } else {
      navigate(`/${anchor}`)
    }
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" id="navigation" style={{ top: 40 }}>
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img
              alt={t("alts.underline-logo")}
              src={Images.logoYellowWhite}
              srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
            />
          </a>
          <a
            onClick={() => { setisActive(!isActive) }}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="true"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-end">
            {menuContent.map((item, index) => {
              const isExternalLink = item.anchor.includes("http")
              return (
                <Link
                  className="navbar-item has-text-weight-medium is-size-4"
                  key={`${index}`}
                  language={isExternalLink ? "pl" : context.language}
                  onClick={(e) => onClick(e, item.anchor)}
                  target={isExternalLink ? "_blank" : ""}
                  to={isExternalLink ? item.anchor : `/${item.anchor}`}
                >
                  {item.label}
                </Link>
              )
            })}
            <div className="navbar-item is-hoverable">
              <a className="navbar-link is-arrowless language-selection is-flex">
                <img 
                  alt={t("alts.current-language")}
                  src={currentFlag === "PL" ? flagPl : flagUs}
                 />
              </a>

              <div className="navbar-dropdown">
                {availableLanguages.map(({ node: item }) => {
                  return (
                    <Link className="navbar-item" to={originalPath} language={item.abbreviation}>
                      <img
                        src={item.flag === "PL" ? flagPl : flagUs}
                        alt={t(`alts.current-language-${item.flag}`)}
                      />
                      <span className="has-text-weight-semibold">{item.name}</span>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav >
  )
}