import { I18nextContext, Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { TranslationContext, LearnMoreContext } from '../../services/context/translationContext'

export const LearnMore = (props) => {
  const { yellow, text } = props
  
  const handleClick = () => {
    scrollTo("#footer_download_buttons")
    // modal.setIsVisibleModal(true)
  }
  return (
    <TranslationContext.Consumer>
      {(copy) => {
        return (
          <LearnMoreContext.Consumer>
            {(modal) => {
            return (
            <ButtonAsButton
            label={text || copy.buttonCta}
            style={{marginRight: 32}}
            yellow={yellow}
            onClick={handleClick}
           />
          )
            }}
          </LearnMoreContext.Consumer>
          
        )
      }}

    </TranslationContext.Consumer>
  )
}

export const Button = ({ path, label, ...props }) => {
  const context = React.useContext(I18nextContext);
  return (
    <Link
      className="button is-primary has-text-weight-semibold is-3"
      language={context.language}
      to={path}
      {...props}
    >
      {label}
    </Link>
  )
}

export const ButtonAsButton = ({ path, label, yellow, ...props }) => {
  const context = React.useContext(I18nextContext);

  return (
    <button
      className={`button is-primary has-text-weight-semibold is-3 ${yellow && 'yellow-button'}`}
      {...props}
    >
      {label}
    </button>
  )
}

export const ButtonAsButtonInverted = ({ path, label, className, ...props}) => {
  const context = React.useContext(I18nextContext);
  return (
    <button
      className={`button is-primary is-outlined has-text-weight-semibold is-3 ${className}`}
      {...props}
    >
      {label}
    </button>
  )
}


export const ButtonInverted = ({ path, label, className, ...props}) => {
  const context = React.useContext(I18nextContext);
  return (
    <Link
      className={`button is-primary is-outlined has-text-weight-semibold is-3 ${className}`}
      language={context.language}
      to={path}
      {...props}
    >
      {label}
    </Link>
  )
}