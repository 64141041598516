import React from 'react'
import styled from 'styled-components'
import { AllAnimationsCombined } from '../../components/Animation/Animation'
import { LearnMore } from '../../components/LearnMore/LearnMore'
import Images from '../../images'
import '../../styles/style.scss'

const Shadow = styled.div`
  background-image: url(${Images.shadows});
  background-repeat: no-repeat;
  background-position: center;
  min-height: 200px;
`

export const PurpleSection = ({ data }) => {
  const { subtitle, headline, howItWorks, progressMonitorCopy, progressMonitorHeader, progressMonitorImage } = data
  return (
    <>

      <section className="hero" style={{ backgroundColor: '#EAEDFF', position: 'relative' }}>
        <AllAnimationsCombined purpleSection />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-justify-content-center is-flex image-container is-hidden-mobile">
                <img
                  alt={progressMonitorImage.alt}
                  src={progressMonitorImage.dpr1}
                  srcSet={`${progressMonitorImage.dpr2} 2x, ${progressMonitorImage.dpr3} 3x`}
                />
              </div>
              <div className="column is-half">
                <h4 className="text-center-mobile subtitle has-text-weight-semibold is-size-4">
                  {howItWorks}
                </h4>
                <h2
                  className="text-center-mobile title is-2 has-text-weight-bold"
                  style={{ lineHeight: '150%', }}
                  dangerouslySetInnerHTML={{ __html: progressMonitorHeader }}
                />
                <p
                  className="title is-4 has-text-weight-normal has-text-grey"
                  style={{ lineHeight: '160%', whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: progressMonitorCopy }} />
                <div className='text-center-mobile'>
                  <LearnMore />
                </div>
              </div>
              <div className="column is-justify-content-center is-flex image-container is-hidden-tablet" style={{ marginTop: 20 }}>
                <img
                  src={Images.homeProgress}
                  srcSet={`${Images.homeProgress2x} 2x, ${Images.homeProgress3x} 3x`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}
