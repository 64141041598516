import React from 'react'
import { AllAnimationsCombined } from '../../components/Animation/Animation'
import { LearnMore } from '../../components/LearnMore/LearnMore'
import '../../styles/style.scss'


const HowItWorksHeader = (props) => {
  const { howItWorks, howItWorksHeader, howItWorksCopy, howItWorksCta } = props.data

  return (
    <header className="has-text-centered section-header">
      <h4 className="text-center-mobile subtitle has-text-weight-semibold is-size-4 has-text-white">
        {howItWorks}
      </h4>
      <h2
        className="text-center-mobile has-text-weight-bold is-size-2 has-text-white"
        dangerouslySetInnerHTML={{ __html: howItWorksHeader }} />
      <p
        className="has-text-white"
        style={{ marginTop: 24 }}
        dangerouslySetInnerHTML={{ __html: howItWorksCopy }} />
      <div className="mt-5">
        <LearnMore yellow text={howItWorksCta} />
      </div>
    </header>
  )
}

export const HowItWorks = (props) => {
  const { howItWorksImage } = props.data
  return (
    <section
      id="howItWorks"
      className="hero"
      style={{ background: '#6367FD', marginTop: 60, position: 'relative' }}
    >
      <AllAnimationsCombined howItWorks />
      <div className="hero-body" style={{ paddingBottom: 0, marginTop: 160 }}>
        <div className="container">
          <HowItWorksHeader {...props} />
          <div className="column has-text-centered is-flex is-align-items-center is-justify-content-center" style={{ padding: 0, marginTop: 64, marginBottom: 160 }}>
            <img
              alt={howItWorksImage.alt}
              src={howItWorksImage.dpr1}
              srcSet={`${howItWorksImage.dpr2} 2x, ${howItWorksImage.dpr2} 3x`}
              style={{ position: 'relative', }}
            />
          </div>

        </div>
      </div>
    </section>
  )

}
