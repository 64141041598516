import { Landing } from '../containers/Landing/Landing';

export default Landing

export const query = graphql`
query MyQuery($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allDatoCmsPageLanguage( filter: {locale: {eq: $language}}) {
    edges {
      node {
        name
        flag
        abbreviation
        flag
      }
    }
  }
  datoCmsLanding(locale: {eq: $language}) {
      customerReview {
        author
        content
      }
      menu {
        label
        anchor
      }
      progressMonitorHeader
      progressMonitorCopy
      progressMonitorImage {
          alt
          dpr1: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 1})
          dpr2: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 2})
          dpr3: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 3})
      }
      dowiedzSieWiecejHeader
      dowiedzSieWiecejSubtitle
      dowiedzSieWiecejCopy
      dowiedziSieWiecejCta
      dowiedzSieWiecejHeader
      buttonCta
      headline
      seeInAction
      subtitle
      howItWorksHeaderWhite
      howItWorksWhite {
        header
        howItWorksCopy
        howItWorkImage {
          alt
          dpr1: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 1})
          dpr2: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 2})
          dpr3: url(imgixParams: {q: 100, h: "720", w: "332", dpr: 3})
        }
      }
      topImage {
        blurhash
        alt
        url(imgixParams: {q: 70, h: "720", w: "332"})
      }
      aboutUnderline
      aboutUnderlineHeader
      howItWorks
      privacyPolicy
      howItWorksHeader
      howItWorksCopy
      howItWorksCta
      howItWorksImage {
        alt
        dpr1: url(imgixParams: {q: 100, h: "448", w: "954", dpr: 1})
        dpr2: url(imgixParams: {q: 100, h: "448", w: "954", dpr: 2})
        dpr3: url(imgixParams: {q: 100, h: "448", w: "954", dpr: 3})
      }
      whyUnderline {
        id
        copy
        headline
        iconBackgroundColor {
          hex
        }
        icon {
          alt
          url
        }
      }
      contactForm {
        label
        name
        placeholder
        inputType
      }
      checkboxTermsConditions
    }
  }
`;

