/* global fbq */
import Images from '../../images'
import React from 'react'
import styled from 'styled-components'
import { useTranslationInternal } from '../../services/helpers'

const EXTENSION_URL = "https://chrome.google.com/webstore/detail/underline/ogdfpjfkomoeoodbemmkloabcjkmmbol"
const APPLE_URL = "https://apps.apple.com/pl/app/underline-nauka-przez-fiszki/id1571773059"
const ANDROID_URL = "https://play.google.com/store/apps/details?id=com.fiszki"

export const DownloadButtons = ({ buttonCta, id, location }) => {
  const t = useTranslationInternal()

  const handleClick = (url, target) => () => {
    window.open(url, target)
  }

  return (
    <div className="hero-body" style={{ padding: 0 }}>
      <div className="container">
        <Box className="columns" id={id}>

            <div className="column mobile-apps-download">
              <p style={{ color: '#1E222D', fontSize: 20, fontWeight: 600 }}>
                {t('topSection.mobile')}
              </p>
              <div style={{ marginTop: 24 }}>
                <a
                  alt={t("alts.try-appstore")}
                  id={`logo-appstore-${location}`}
                  onClick={handleClick(APPLE_URL, '_blank')}
                  >
                  <img
                  style={{ marginRight: 25 }}
                    src={Images.macAppStore}
                    srcSet={`${Images.macAppStore2x} 2x, ${Images.macAppStore3x} 3x`}
                  />
                </a>
                <a
                  alt={t("alts.try-googleplay")}
                  id={`logo-googleplay-${location}`}
                  onClick={handleClick(ANDROID_URL, '_blank')}
                  >
                  <img
                    src={Images.googlePlay}
                    srcSet={`${Images.googlePlay2x} 2x, ${Images.googlePlay3x} 3x`}
                  />
                </a>
              </div>
            </div>

          <div className="column">
            <p style={{ color: '#1E222D', fontSize: 20, fontWeight: 600 }}>
              {t('topSection.chrome')}
            </p>
            <div className="is-flex works-also-in-container">
              <a
                id={`logo-chrome-${location}`}
                onClick={handleClick(EXTENSION_URL, '_blank')}
                className={`button is-primary has-text-weight-semibold is-3 black-button`}
              >
                <img
                  alt={t('alts.try-chrome')}
                  style={{ marginRight: 16 }}
                  src={Images.chrome}
                  srcSet={`${Images.chrome2x} 2x, ${Images.chrome3x} 3x`}
                />
                {buttonCta}
              </a>
              <div className="works-also-in-section">
                <p style={{ color: '#566280', fontSize: 16, fontWeight: 600, marginRight: 16 }}>
                  {t('topSection.worksIn')}
                </p>
                <a onClick={handleClick(EXTENSION_URL, '_blank')} id={`logo-opera-${location}`}>
                  <img
                    alt={t('alts.try-opera')}
                    style={{ marginRight: 16 }}
                    src={Images.operaLogo}
                    srcSet={`${Images.operaLogo2x} 2x, ${Images.operaLogo3x} 3x`}
                  />
                </a>
                <a onClick={handleClick(EXTENSION_URL, '_blank')} id={`logo-edge-${location}`}>
                  <img
                    alt={t('alts.try-edge')}
                    style={{ marginRight: 16 }}
                    src={Images.microsoftEdge}
                    srcSet={`${Images.microsoftEdge2x} 2x, ${Images.microsoftEdge3x} 3x`}
                  />
                </a>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  )
}

const Box = styled.div`
  border: 1.5px solid #EAF3FF !important;
  border-radius: 8px;
  background-color: #EAF3FF;
  padding: 32px;
`