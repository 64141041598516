import React from 'react'
import { SingleColumn } from '../../components/SingleColumn/SingleColumn'

const WhyUnderlineHeader = (props) => {
  const { data: { aboutUnderline, aboutUnderlineHeader } } = props
  return (
    <header className="has-text-centered section-header">
      <h4 className="subtitle has-text-weight-semibold is-size-4">
        {aboutUnderline}
      </h4>
      <h2
        className="has-text-weight-bold is-size-2"
        style={{ color: '#1E222D' }}
      >
        {aboutUnderlineHeader}
      </h2>
    </header>
  )
}

export const WhyUnderline = (props) => {
  const { data: { whyUnderline } } = props
  return (
    <section className="hero" style={{ marginTop: 120 }} id="aboutUnderline">
      <div className="hero-body">
        <div className="container">
          <WhyUnderlineHeader {...props} />
          <div className="columns" style={{ marginTop: 80 }}>
            {whyUnderline.map(item =>
              <SingleColumn
                key={item.id}
                icon={item.icon.url}
                alt={item.icon.alt}
                iconBackgroundColor={item.iconBackgroundColor.hex}
                headline={item.headline}
                copy={item.copy}
              />
            )}
          </div>
        </div>
      </div>

    </section>
  )
}