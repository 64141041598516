import React from 'react'
import styled from 'styled-components'
import { AllAnimationsCombined } from '../../components/Animation/Animation'
import { LandingPageImage } from '../../components/LandingPageImage'
import { LearnMore } from '../../components/LearnMore/LearnMore'
import Images from '../../images'
import '../../styles/style.scss'

export const LearningMode = ({ data, item, index }) => {
  const { subtitle, headline, howItWorksHeaderWhite } = data
  return (
    <>
      <section className="hero" style={{ marginTop: -70, position: 'relative' }}>
        <AllAnimationsCombined learningModeSection />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-half">
                <h4 className="text-center-mobile subtitle has-text-weight-semibold is-size-4">
                  {howItWorksHeaderWhite}
                </h4>
                <h2 className="text-center-mobile title is-2 has-text-weight-bold" style={{ lineHeight: '150%', maxWidth: 568, marginTop: '-8px' }}>
                  {item.header}
                </h2>
                <p
                  className="title is-4 has-text-weight-normal has-text-grey"
                  style={{ lineHeight: '160%', whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: item.howItWorksCopy }} />
                <div className='text-center-mobile'>
                  <LearnMore />
                </div>
              </div>

              <LandingPageImage
                withoutShadow={index === "custom"}
                src={item.howItWorkImage.dpr1}
                alt={item.howItWorkImage.alt}
                srcSet={`${item.howItWorkImage.dpr2} 2x, ${item.howItWorkImage.dpr3} 3x`}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )

}
