import { useI18next } from 'gatsby-plugin-react-i18next'
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { AllAnimationsCombined } from '../../components/Animation/Animation'

import { LandingPageImage } from '../../components/LandingPageImage'
import { ButtonAsButton, ButtonAsButtonInverted } from '../../components/LearnMore/LearnMore'
import Images from '../../images'
import { useTranslationInternal } from '../../services/helpers'
import { languages as allLanguages } from '../../services/languagesData'
import '../../styles/style.scss'
import { DownloadButtons } from './DownloadButtons'

const DisplayImage = (props) => {
  const {Image, language} = props
  const { t } = useI18next();
  return (
    <div style={{border: '1px solid #D5DEF1', borderRadius: 8, flexDirection: 'row', flex: 1, display: 'flex', padding: '16px 24px', alignItems: 'center', marginRight: 36}}>
        <div style={{width: 36, alignItems: 'center', display: 'flex', marginRight: 16}}>
          <Image style={{ height: 24, width: 36}} />
        </div>
        <p style={{fontWeight: 600, color: '#1E222D'}}>{t(`languages.${language}`)}</p>
    </div>
  )
}

export const TopSection = ({ data, setIsVisibleModal }) => {
  const { subtitle, headline, buttonCta } = data
  const t = useTranslationInternal()

  return (
    <section className="hero" style={{ position: 'relative', marginTop: 90 }}>
      <AllAnimationsCombined topSection/>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="main-column-landing">
              <h1 className="text-center-mobile title is-1 has-text-weight-bold" style={{ lineHeight: '150%' }}
                  dangerouslySetInnerHTML={{ __html: headline }}
              />
              <p
                className="title is-3 has-text-weight-normal has-text-grey text-center-mobile"
                style={{ lineHeight: '160%', whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />

              <ButtonAsButton
                label={t("topSection.cta")}
                style={{marginRight: 32, marginBottom: 20}}
                // onClick={() => setIsVisibleModal(true)}
                onClick={() => scrollTo("#footer_download_buttons")}
               />

              <ButtonAsButtonInverted
                onClick={() => scrollTo("#aboutUnderline")}
                className="responsive-margin"
                label={t("topSection.watchVideo")}
              />

              <div style={{marginTop: 100}}>
              <h4 className="subtitle has-text-weight-semibold is-size-4" style={{marginBottom: 16}}>
                {t("topSection.participatedIn")}
              </h4>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <a href="https://hugething.vc" target="_blank">
                    <img
                      alt={t("alts.huge-thing-alumns")}
                      style={{marginRight: 48}}
                      src={Images.hugeThingLogo}
                      srcSet={`${Images.hugeThingLogo2x} 2x, ${Images.hugeThingLogo3x} 3x`}
                    />
                  </a>
                  <a href="https://startup.google.com" target="_blank"> 
                  <img
                    alt={t("alts.google-alumns")}
                    src={Images.logoGoogleForStartups}
                    srcSet={`${Images.logoGoogleForStartups2x} 2x, ${Images.logoGoogleForStartups3x} 3x`}
                  />
                  </a>
                </div>
              </div>
            </div>
            <LandingPageImage
              alt={t("alts.mockup-phone-landing")}
              style={{ marginTop: -30, marginBottom: 0 }}
              src={Images.mockupPhoneLanding}
              srcSet={`${Images.mockupPhoneLanding2x} 2x, ${Images.mockupPhoneLanding3x} 3x`}
            />
          </div>
        </div>
      </div>
      <DownloadButtons
        location="top"
        buttonCta={buttonCta}
      />
      <div className="hero-body" style={{ padding: 0, marginTop: 65 }}>
        <div className="container" >
          <div className='columns'>
            <div className='column' style={{overflow: 'hidden'}}>
              <div style={{flexDirection: 'row', display: 'flex'}} className="animate-scroll">
                {allLanguages.map(item => {
                return <DisplayImage {...item} />
              })}
            </div>
          </div>
          </div>
        </div>
      </div>
      
    </section>
  )
}