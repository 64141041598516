import React from 'react'
import { colors } from '../../theme'


export const SingleColumn = (props) => {
  const {
    iconBackgroundColor,
    icon,
    headline,
    copy,
    alt
  } = props
  return (
    <div className="column has-text-centered-mobile">
      <div className="is-fullheight">
        <figure
          className="icon is-large"
          style={{ backgroundColor: iconBackgroundColor, borderRadius: 8, }}
        >
          <img src={icon} style={{ width: 24 }} alt={alt} />
        </figure>
        <h3
          className="title is-3"
          style={{ lineHeight: '160%', marginTop: 24, marginBottom: 16 }}
        >
          {headline}
        </h3>
        <p
          className="is-size-4"
          style={{ color: colors.blueBayoux, lineHeight: '160%' }}
        >
          {copy}
        </p>
      </div>
    </div>
  )
}