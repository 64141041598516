import React from 'react'
import styled from 'styled-components'
import { AllAnimationsCombined } from '../../components/Animation/Animation'
import { LearnMore } from '../../components/LearnMore/LearnMore'
import Images from '../../images'
import '../../styles/style.scss'

const Shadow = styled.div`
  background-image: url(${Images.shadows});
  background-repeat: no-repeat;
  background-position: center;
  min-height: 200px;
`

export const LearnWhatYouNeed = ({ data, item }) => {
  const { subtitle, headline, howItWorksHeaderWhite } = data
  return (
    <>
      <section className="hero" style={{ position: 'relative' }}>
        <AllAnimationsCombined learnWhatYouNeed />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-justify-content-center is-flex image-container is-hidden-mobile">
                <img
                  alt={item.howItWorkImage.alt}
                  src={item.howItWorkImage.dpr1}
                  srcSet={`${item.howItWorkImage.dpr2} 2x, ${item.howItWorkImage.dpr3} 3x`}
                />
              </div>
              <div className="column is-half">
                <h4 className="text-center-mobile subtitle has-text-weight-semibold is-size-4">
                  {howItWorksHeaderWhite}
                </h4>
                <h2 className="text-center-mobile title is-2 has-text-weight-bold" style={{ lineHeight: '150%', maxWidth: 568 }}>
                  {item.header}
                </h2>
                <p
                  className="text-center-mobile title is-4 has-text-weight-normal has-text-grey"
                  style={{ lineHeight: '160%', whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: item.howItWorksCopy }} />
                <div className='text-center-mobile'>
                  <LearnMore />
                </div>
                <div className="column is-justify-content-center is-flex image-container is-hidden-tablet" style={{ marginTop: 20 }}>
                  <img
                    alt={item.howItWorkImage.alt}
                    src={item.howItWorkImage.dpr1}
                    srcSet={`${item.howItWorkImage.dpr2} 2x, ${item.howItWorkImage.dpr3} 3x`}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )

}
