import { graphql, useStaticQuery } from "gatsby"
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, image: metaImage, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )
  const {t} = useTranslation();
  const { language } = useI18next()
  const metaDescription = description || site.siteMetadata.description
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const getCanonicals = () => {
    if (language === "en") {
      return  [
        {
          rel: "canonical",
          href: "https://getunderline.io/en/",
        },
        {
          rel: "alternate",
          href: "https://getunderline.io",
          hrefLang: 'pl'
        },
        {
          rel: "alternate",
          href: "https://getunderline.io/en/",
          hrefLang: 'en'
        },
      ]
    }
    return  [
      {
        rel: "canonical",
        href: "https://getunderline.io",
      },
      {
        rel: "alternate",
        href: "https://getunderline.io",
        hrefLang: 'pl'
      },
      {
        rel: "alternate",
        href: "https://getunderline.io/en/",
        hrefLang: 'en'
      },
    ]
  }

  return (
    <Helmet
      htmlAttributes={{ lang: language}}
      title={t('seo.title')}
      link={getCanonicals()}
      meta={[
        {
          name: "facebook-domain-verification",
          content: "3dy2e35d3boc2fsid2y2829af6lznr"
        },
        {
          name: `description`,
          content: t('seo.description'),
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:title`,
          content: t('seo.title'),
        },
        {
          property: `og:description`,
          content: t('seo.description'),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: t('seo.title'),
        },
        {
          name: `twitter:description`,
          content: t('seo.description'),
        },
      ]
        .concat(
          metaImage
            ? [
              {
                property: "og:image",
                content: image,
              },
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(meta)}
    />

  
  )
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}
export default SEO