import { useI18next } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Images from '../images'


export const PRIVACY_POLICY_URL = "https://docs.google.com/document/d/1kKOyWmcThexpCw5JbPvkdsoguF2wurqixAQwO1ELAjU/edit?usp=sharing"
export const TERMS_OF_USE_URL = "https://docs.google.com/document/d/1sU_9q96lgBOrmDSA7V-DOrIbmHXvL-c1pc6WLQqZ6fY/edit?usp=sharing"

const LINKEDIN_URL = "https://www.linkedin.com/company/underlinepl/"
const TWITTER_URL = "https://twitter.com/underline_app"
const INSTAGRAM_URL = "https://www.instagram.com/underline.app/"
const FACEBOOK_URL = "https://www.facebook.com/Underline-227728845349088"
const TIK_TOK = "https://www.tiktok.com/@underline.app"
export const Footer = (props) => {
  // const { privacyPolicy } = props.data
  const { t } = useI18next()
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns is-desktop">
          <div className="column is-flex is-flex-grow-5">
            <a className="mr-6 navbar-item has-text-weight-medium is-size-4">
              Copyright by Underline
            </a>
            <a href={PRIVACY_POLICY_URL} target="_blank" className="mr-1 navbar-item has-text-weight-medium is-size-4">
              {t('footer.privacyPolicy')}
            </a>
            <a href={TERMS_OF_USE_URL} target="_blank" className="mr-1 navbar-item has-text-weight-medium is-size-4">
              {t('footer.termsOfUse')}
            </a>

           
          </div>
          <div className="column is-flex " style={{alignItems: 'center'}}>
            <a href="mailto:ada@getunderline.io" style={{color: '#1E222D'}}>ada@getunderline.io</a>
          </div>
          <div className="column is-flex  is-flex-direction-row">

            <a href={TIK_TOK} target="_blank" className="mr-1 navbar-item has-text-weight-medium is-size-4">
              <Images.TikTok />
            </a>
            <a href={INSTAGRAM_URL} target="_blank" className="mr-1 navbar-item has-text-weight-medium is-size-4">
              <Images.Instagram />
            </a>
            <a href={FACEBOOK_URL} target="_blank" className="mr-1 navbar-item has-text-weight-medium is-size-4">
              <Images.Facebook />
            </a>
          </div>
        </div>

      </div>
    </footer>
  )
}