import React from 'react'
import styled from 'styled-components'
import Images from '../../images'
import { useTranslationInternal } from '../../services/helpers'

const SingleReviewContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #D5DEF1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  max-width: 368px
`

const SingleReview = (props) => {
  return (
    <SingleReviewContainer style={{height: '100%'}}>
      <Images.PersonAvatar />
      <p className='text-center-mobile' style={{ color: '#566280', fontSize: 16, marginTop: 16, fontWeight: 400 }}>
        {props.content}
      </p>
      <p className='text-center-mobile' style={{ color: '#1E222D', fontSize: 16, marginTop: 8, fontWeight: 500, }}>
        {props.author}
      </p>
    </SingleReviewContainer>
  )
}

export const CustomerReviews = (props) => {
  const reviews = props.data.customerReview
  const t = useTranslationInternal()
  return (
    <section className="hero" style={{ marginTop: 120 }}>
      <div className="hero-body">
        <div className="columns is-vcentered">
          <div className="column has-text-centered">
            <h4 className="subtitle has-text-weight-semibold is-size-4" style={{ lineHeight: '170%' }}>
              {t('customerReviews.header')}
            </h4>
            <h2 className="title is-2 has-text-weight-bold" style={{ lineHeight: '150%', }}>
              {t('customerReviews.copy')}
            </h2>
          </div>
        </div>
        <div className="container" style={{ marginTop: 80, display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'center' }}>
          <div className="columns">
          {reviews.map(item => {
            return (
            <div className="column">
              <SingleReview content={item.content} author={item.author} />
            </div>
            )
          })}
          </div>
        </div>
      </div>
    </section>
  )
}